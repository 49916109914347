import type { NextPage } from 'next';

import { SCHEDULE_DATA, STAGE_ONE_VIDEO_PLAYER_URL } from 'libs/constants';

import { StageLayout, VideoPlayer, StageSchedule } from 'components';

const StageOne: NextPage & { auth: boolean } = () => (
  <StageLayout>
    <VideoPlayer url={STAGE_ONE_VIDEO_PLAYER_URL} />
    <StageSchedule scheduleData={SCHEDULE_DATA.stageOne} />
  </StageLayout>
);

StageOne.auth = true;

export default StageOne;
